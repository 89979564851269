import React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { useI18n, LocaleLink as Link } from "../../contexts/i18n"
import Layout from "../../layout"
import SEO from "../../components/SEO"
import { DangerousHTML } from "../../components/contentful/HTML"
import ModalPlayer from "../../components/ModalPlayer"
import { watchPath } from "../../../routes"

// @ts-ignore
import * as styles from "../movie-and-show.module.scss"

interface DataType {
  show: Contentful.Show
}

function ShowTemplate({ data }: PageProps<DataType>) {
  const { translate: t } = useI18n(`movieTemplate`)

  const firstEp = data.show.seasons?.[0].episodes?.[0]
  const isWatchable = !!firstEp.vimeoId

  return (
    <Layout>
      <SEO {...data.show.metadata} />

      <h1 className={styles.movieTitle}>{data.show.title}</h1>

      <div className={styles.movieGrid}>
        <GatsbyImage
          className={`${styles.moviePageImage} ${styles.vertical}`}
          alt={data.show.title}
          image={data.show.posterImageVertical.gatsbyImage}
        />
        <GatsbyImage
          className={`${styles.moviePageImage} ${styles.horizontal}`}
          alt={data.show.title}
          image={data.show.posterImageHorizontal.gatsbyImage}
        />

        <div className={styles.movieInfo}>
          <div className={styles.movieInfoButtonGroup}>
            {isWatchable && firstEp?.slug && (
              <Link to={watchPath(firstEp.slug)} title="Watch" className="cta-link">
                {" "}
                Watch
              </Link>
            )}

            {data.show.trailerUrl && <ModalPlayer title={t`Trailer`} url={data.show.trailerUrl} />}
            {data.show.extraUrl && <ModalPlayer title={t`Extras`} url={data.show.extraUrl} />}
          </div>
          <span className={styles.movieInfoRating}>Rated: {data.show.rating}</span>

          <h2 className={styles.movieInfoHeading}>Principal Cast</h2>
          <p>{(data.show.principalCast || []).join(`, `)}</p>

          <h2 className={styles.movieInfoHeading}>Category</h2>
          <p>{[...(data.show.genre || [])].join(`, `)}</p>

          <h2 className={styles.movieInfoHeading}>Synopsis</h2>
          <DangerousHTML>{data.show.description}</DangerousHTML>

          <h2 className={styles.movieInfoHeading}>Seasons</h2>
          {!!data.show.seasons &&
            data.show.seasons.map((season, i) => {
              return (
                <div className={styles.seasonSection}>
                  <h3 className={styles.seasonName}>Season {i + 1}</h3>
                  <ul className={styles.seasonList}>
                    {season.episodes.map((episode) => (
                      <li>
                        <Episode {...episode} />
                      </li>
                    ))}
                  </ul>
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export default ShowTemplate

export const pageQuery = graphql`
  query ($slug: String!, $locale: String!) {
    show: contentfulShow(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      genre
      gcFlixOriginal
      principalCast
      rating
      slug
      posterImageVertical {
        gatsbyImage: gatsbyImageData(width: 300)
      }
      posterImageHorizontal {
        gatsbyImage: gatsbyImageData(width: 600)
      }
      trailerUrl
      extraUrl
      description {
        childMarkdownRemark {
          html
        }
      }
      metadata {
        title
        description
        keywords
      }
      seasons {
        title
        episodes {
          year
          title
          slug
          vimeoId
        }
      }
    }
  }
`

function Episode({ title, slug, vhxId, vimeoId, allowVhx }: Contentful.Flick) {
  const isWatchable = (allowVhx && vhxId) || vimeoId

  return !isWatchable ? (
    <>&#x25b6; {title}</>
  ) : (
    <Link to={watchPath(slug)} title={title} className={styles.episodeLink}>
      &#x25b6; {title}
    </Link>
  )
}
